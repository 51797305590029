<template>
<section class="wrapper bg-light">
	<div class="container py-14 py-md-16">
		<div class="row align-items-center mb-10">
			<div v-if="$i18n.locale == 'fr'">
				<h2 class="display-4 mb-0">
					Obtenir des clés sur la blockchain Stellar</h2>

				<h2>
					Principe de base</h2>
				<p>
					Une personne désireuse de rejoindre l'écosystème Fraction.re doit disposer de clés sur la blockchain Stellar
					pour signer des transactions de pair à pair.
					Pour cela, il faut utiliser un Wallet

					<br />
				</p>
				<h2>
					Pas d'obligation déclarative</h2>
				<p>
					À défaut de précision de la doctrine fiscale, l’article 1649 bis C qui impose une déclaration fiscale des comptes de cryptoactifs vise « les comptes d’actifs numériques mentionnés à l’article 150 VH bis du CGI ».
					Or, l'utilisation du terme « compte » pour les plateformes de DeFi est un abus de langage.
					En effet, l’utilisation de ces plateformes n’implique pas l’ouverture d’un compte à proprement parler,
					puisque les échanges sont réalisés entre les utilisateurs mis en relation en peer-to-peer.
					Dès lors, une interprétation stricte du texte conduit à exclure les plateformes de finance décentralisée du champ d’application
					de l’obligation déclarative.
					<br /><b>Vous n'avez donc pas à déclarer vos clés sur la blockchain Stellar</b>
				</p>
				<h2>
					Un exemple concret</h2>
				<p>
					Léa va utiliser SolarWallet, le Wallet officiel de la blockchain Stellar pour obtenir des clés. <br />
				</p>

				<h3>
					Télachargement de l'application </h3>
				<p>
					<a target="_blank"
						 href="https://solarwallet.io/"> Léa commence par télécharger le Wallet officiel en suivant ce lien.
					</a>
				</p>
				<div class="row align-items-center mb-10">
					<div class="col">
						<v-btn text>
							<a style="text-decoration:none;"
								 href="https://itunes.apple.com/us/app/solar-stellar-wallet/id1458490218?mt=8">
								<img class="mx-auto"
										 style="width:60px"
										 src="@/assets/apple.svg"
										 alt="Apple iOS">
							</a>
						</v-btn>
					</div>

					<div class="col">
						<v-btn text>
							<a style="text-decoration:none;"
								 href="https://play.google.com/store/apps/details?id=io.solarwallet">
								<img class="mx-auto"
										 style="width:60px"
										 src="@/assets/android.svg"
										 alt="Android">
							</a>
						</v-btn>
					</div>

					<div class="col">
						<v-btn text>
							<a style="text-decoration:none;"
								 href="hhttps://github.com/satoshipay/solar/releases/download/v0.28.1/Solar.Wallet.Setup.0.28.1.exe">
								<img class="mx-auto"
										 style="width:60px"
										 src="@/assets/windows.svg"
										 alt="Android">
							</a>
						</v-btn>
					</div>

				</div>




				<h3 class="mt-12">
					Pas à pas</h3>


				<p>Copie d'écran à partir de la version pour smartphone <b>Android</b>.</p>


				<div class="row ">
					<div class="col-6 col-md-6 mb-4">
						<img style="border: 1px solid silver; max-width:100%"
								 class="mt-10"
								 src="https://media.berlcoin.fr/createaccount-1.png" />
						<br /> <br /> <b> Sur l'écran initial, Léa appuit sur le bouton "Add account"</b><br />
					</div>
					<div class="col-6 col-md-6 mb-4">
						<img style="border: 1px solid silver; max-width:100%"
								 class="mt-10"
								 src="https://media.berlcoin.fr/createaccount-2.png" />
						<br /> <br /> <b> Léa n'a pas de clés, elle appuit donc sur "Create Account" </b><br />
					</div>
					<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
								 class="mt-10"
								 src="https://media.berlcoin.fr/createaccount-3.png" /><br />
						<br /><b>Léa choisie un mot de passe pour proteger sa clé secréte.
						</b>
					</div>

					<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
								 class="mt-10"
								 src="https://media.berlcoin.fr/createaccount-4.png" /><br />
						<br /><b>Léa ajoute un autre mot de passe de récupération.
						</b>
					</div>


				</div>

				<h3 class="mt-12">
					Les clés sont disponibles</h3>

				<div class="row ">

					<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
								 class="mt-10"
								 src="https://media.berlcoin.fr/createaccount-5.png" /><br />
						<br /><b>La clé privée, qui sert à signer les transation s'affiche. Il est fortemrent conseillé de l'imprimer et de la conservé en lieu sûr.
						</b>
					</div>


					<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
								 class="mt-10"
								 src="https://media.berlcoin.fr/createaccount-6.png" /><br /> <br /><b>
							L'ecran principal du compte, s'affiche, Léa peut désormais recevoir des Lumens ou en acheter. Elle appuit sur "RECEIVE"
						</b></div>

					<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
								 class="mt-10"
								 src="https://media.berlcoin.fr/createaccount-10.png" /><br /> <br /><b>
							La clé publique s'affiche. C'est cette clé que Léa transmet à ses partenaires pour recevoir des tokens. </b></div>



					<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
								 class="mt-10"
								 src="https://media.berlcoin.fr/createaccount-6.png" /><br /> <br /><b>
							Retour à l'écran principal du compte, Léa va acheter des Lumens et appuit sur "Deposit Funds"
						</b></div>

					<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
								 class="mt-10"
								 src="https://media.berlcoin.fr/createaccount-7.png" /><br /> <br /><b>
							Léa décide d'ajouter des fonds directement en XLM, qui est le code officiel du Lumens, la cryptomonnaie utilisé sur Stellar.
						</b></div>




					<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
								 class="mt-10"
								 src="https://media.berlcoin.fr/createaccount-9.png" /><br />
						<br /><b>
							Léa va être redirigé vers le site d'une société de service monétaire MonnPay, l'un des leader du marché.
						</b>
					</div>



				</div>



				<div class="row ">
					<h3 class="mt-12">
						Coût de l'opération</h3>
					<p> Aucun, l'obtention de clés est gratuite, par contre, pour que le compte soit actif, il doit y avoir un dépot minimum de 1 Lumens (XLM)
						soit actuellement entre 20 et 25 centimes d'euro.
						Pour être utilisé dans l'écosytème Fraction.re, il est conseillé de conserver 10 Lumens.
					</p>
				</div>



			</div>
		</div>

		<div v-if="$i18n.locale == 'en'">
			<h2 class="display-4 mb-0">
				Getting keys on the Stellar blockchain</h2>

			<h2>
				Basic principle</h2>
			<p>
				A person wishing to join the Fraction.re ecosystem must have keys on the Stellar
				to sign peer-to-peer transactions.
				To do so, one must use a Wallet

				<br />
			</p>

			<h2>
				A concrete example</h2>
			<p>
				Lea will use SolarWallet, the official Wallet of the Stellar blockchain to obtain keys. <br />
			</p>

			<h3>
				Downloading the application </h3>
			<p>
				<a target="_blank"
					 href="https://solarwallet.io/"> Léa starts by downloading the official Wallet by following this link.
				</a>
			</p>
			<div class="row align-items-center mb-10">
				<div class="col">
					<v-btn text>
						<a style="text-decoration:none;"
							 href="https://itunes.apple.com/us/app/solar-stellar-wallet/id1458490218?mt=8">
							<img class="mx-auto"
									 style="width:60px"
									 src="@/assets/apple.svg"
									 alt="Apple iOS">
						</a>
					</v-btn>
				</div>

				<div class="col">
					<v-btn text>
						<a style="text-decoration:none;"
							 href="https://play.google.com/store/apps/details?id=io.solarwallet">
							<img class="mx-auto"
									 style="width:60px"
									 src="@/assets/android.svg"
									 alt="Android">
						</a>
					</v-btn>
				</div>

				<div class="col">
					<v-btn text>
						<a style="text-decoration:none;"
							 href="hhttps://github.com/satoshipay/solar/releases/download/v0.28.1/Solar.Wallet.Setup.0.28.1.exe">
							<img class="mx-auto"
									 style="width:60px"
									 src="@/assets/windows.svg"
									 alt="Android">
						</a>
					</v-btn>
				</div>

			</div>




			<h3 class="mt-12">
				Step by step</h3>


			<p>Screenshot from the <b>Android</b> smartphone version..</p>


			<div class="row ">
				<div class="col-6 col-md-6 mb-4">
					<img style="border: 1px solid silver; max-width:100%"
							 class="mt-10"
							 src="https://media.berlcoin.fr/createaccount-1.png" />
					<br /> <br /> <b> On the initial screen, Lea presses the "Add account" button</b><br />
				</div>
				<div class="col-6 col-md-6 mb-4">
					<img style="border: 1px solid silver; max-width:100%"
							 class="mt-10"
							 src="https://media.berlcoin.fr/createaccount-2.png" />
					<br /> <br /> <b> Lea doesn't have any keys, so she presses "Create Account". </b><br />
				</div>
				<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
							 class="mt-10"
							 src="https://media.berlcoin.fr/createaccount-3.png" /><br />
					<br /><b>Lea chose a password to protect her secret key.
					</b>
				</div>

				<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
							 class="mt-10"
							 src="https://media.berlcoin.fr/createaccount-4.png" /><br />
					<br /><b>Lea adds another recovery password.
					</b>
				</div>


			</div>

			<h3 class="mt-12">
				Les clés sont disponibles</h3>

			<div class="row ">

				<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
							 class="mt-10"
							 src="https://media.berlcoin.fr/createaccount-5.png" /><br />
					<br /><b>The private key, which is used to sign transations, is displayed. It is strongly recommended to print it and keep it in a safe place.
					</b>
				</div>


				<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
							 class="mt-10"
							 src="https://media.berlcoin.fr/createaccount-6.png" /><br /> <br /><b>
						The main screen of the account is displayed, Lea can now receive Lumens or buy them. She presses "RECEIVE".
					</b></div>

				<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
							 class="mt-10"
							 src="https://media.berlcoin.fr/createaccount-10.png" /><br /> <br /><b>
						The public key is displayed. This is the key that Lea sends to her partners to receive tokens. </b></div>



				<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
							 class="mt-10"
							 src="https://media.berlcoin.fr/createaccount-6.png" /><br /> <br /><b>
						Back to the main account screen, Lea goes to buy Lumens and presses "Deposit Funds".
					</b></div>

				<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
							 class="mt-10"
							 src="https://media.berlcoin.fr/createaccount-7.png" /><br /> <br /><b>
						Lea decides to add funds directly in XLM, which is the official code of Lumens, the cryptocurrency used on Stellar.
					</b></div>




				<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
							 class="mt-10"
							 src="https://media.berlcoin.fr/createaccount-9.png" /><br />
					<br /><b>
						Lea will be redirected to the website of a money service company MonnPay, one of the market leaders.
					</b>
				</div>



			</div>



			<div class="row ">
				<h3 class="mt-12">
					Cost of the operation</h3>
				<p> None, obtaining keys is free, however, for the account to be active, there must be a minimum deposit of 1 Lumens (XLM)
					which is currently between 20 and 25 cents.
					To be used in the Fraction.re ecosystem, it is recommended to keep 10 Lumens.
				</p>
			</div>



		</div>
	</div>




</section>
</template>


<script>
export default {
	name: 'smart_open',
	components: {},
	props: {},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {
		if (this.$route.params.lang != undefined) this.$i18n.locale = this.$route.params.lang

	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
